import React from "react";
import styles from "./styles.css";
import Card from "./Card/Card";

function App() {
  return (
    <main className="main-container">
      <header>
        <h1 className={styles.text}>Daniel och Elinas bröllop</h1>
        <h4 className="subheader">31 maj 2025</h4>
      </header>

      <Card
        subject="Välkommen på vårt bröllop!"
        text={
          <>
            <p>
              Vigsel kl. 16 i Rättviks kyrka, entré via sidoingången mot
              parkeringen. Därefter middag och fest hela natten i Vikarbyns
              bystuga.
            </p>
          </>
        }
      />
      <Card
        subject="OSA"
        text={
          <>
            <p>
              Meddela om du kommer eller inte kommer senast 31 mars. Berätta
              gärna om du kommer på både vigseln och middagen. Du får självklart
              ta med din en +1. Meddela oss i så fall.
            </p>

            <address>
              Hör av dig till någon av oss via SMS eller Messenger:
              <p>Daniel: 073-5104224</p>
              <p>Elina: 073-6656753</p>
            </address>
          </>
        }
      />
      <Card subject="Barnfritt" text={<p>Lämna ungarna hemma.</p>} />
      <Card
        subject="Inga presenter tack"
        text={
          <>
            <p>
              Vi behöver inga grejer. Vill du ändå ge en gåva får du gärna bidra
              till vår bröllopsresa.
            </p>
          </>
        }
      />
      <Card
        subject="Klädkod"
        text={
          <>
            <p>
              Klädkoden är kavaj. Det viktigaste är såklart att du känner dig
              fin och bekväm.
            </p>
          </>
        }
      />
      <Card
        subject="Aktiviteter under kvällen"
        text={
          <>
            <p>
              Vill du hålla ett tal, sjunga en sång eller har någon annan kul
              idé till festen? Hör av dig till kulgruppen via SMS eller
              Messenger.
            </p>
            <address>
              <p>Stina Bengtsson: 073 3545611</p>
              <p>Robin Söderberg: 070 2040831</p>
              <p>Melissa Ajosmäki: +35 844 99 42 899</p>
            </address>
          </>
        }
      />
      <Card
        subject="Boende"
        text={
          <>
            <p>Här kommer några tips på boenden i Rättvik:</p>
            <ul>
              <li>Backlunds boende</li>
              <li>Turisthotellet</li>
              <li>Lerdalshöjden</li>
              <li>Fyrklöverns stugby</li>
            </ul>
          </>
        }
      />
      <Card
        subject="Transport"
        text={
          <>
            <p>
              Vi planerar att fixa skjuts från kyrkan till bystugan efter
              vigseln samt skjuts från bystugan till hotellen i Rättvik efter
              festen.
            </p>
          </>
        }
        last={true}
      />
    </main>
  );
}

export default App;
